<template>
 <!-- 发票抬头列表页 -->
 <div class="invoiceTitle" >
	 <van-nav-bar
	   title="发票抬头"
	   right-text="新增"
	   left-arrow
	   @click-left="$router.go(-1)"
	   @click-right="addTitle"
	 />
	<div class="list">
		<div v-for="item in list" @click="updateTitle(item)"  class="item" >
				 <p class="name" >{{item.riseName}}</p>
				 <p v-if="item.riseType==1" class="type">单位</p>
				 <p v-if="item.riseType==2" class="type">个人</p>
		</div>
	</div>
 </div>
</template>

<script>
import { NavBar } from "vant";

export default {
  name: "invoice",
  components: {
	  [NavBar.name]:NavBar
  },
  provide() {
    return {};
  },
  data() {
    return {
		list:[]
	};
  },
  mixins: [],
  watch: {},
  created() {
	  this.getList()
  },
  mounted() {},
  methods: {
	  // 获取抬头列表
	  getList(){
		this.$http.request("riseList",{
			patientId: this.$cache.local.getItem("patientId"),
		})
		  .then((res)=>{
			  this.list=res;
		  })
		  .catch((err)=>{
			  console.log(err)
		  })
	  },
	  addTitle(){
		  this.$router.push({
			  path:"/invoiceTitleChange"
		  })
	  },
	  updateTitle(item){
		  this.$router.push({
			  path:"/invoiceTitleChange",
			  query:{
				  id:item.id,
			  }
		  })
	  },
  },
  beforeUnmount() {},
  // beforeRouteLeave(to,from,next){},
};
</script>
<style scoped lang="scss">
.invoiceTitle{
	height: 100vh;
}
.list{
	height: calc(100% - 50px);
	overflow: auto;
	.item{
		border-bottom: 1px solid #ccc;
		padding: 14px;
		.name{
			font-size: 14px;
			line-height: 30px;
			font-weight: 600;
			padding-bottom: 10px;
		}
		.type{
			font-size: 14px;
		}
	}
}
</style>
